"use client";  // 添加这行因为我们需要使用 useState

import Link from "next/link";
import Image from "next/image";
import config from "@/config";
import logo from "@/public/images/logo.png";
// import shipfast_logo from "@/public/shipfast_logo.png";
import { useTranslations } from 'next-intl';
import { useState, Fragment } from "react";
import { useSearchParams, usePathname } from "next/navigation";
import { GlobeAltIcon, ChevronDownIcon, CheckIcon } from "@heroicons/react/16/solid";
import { Transition } from "@headlessui/react";
import { languages } from '../navigation';

// Add the Footer to the bottom of your landing page and more.
// The support link is connected to the config.js file. If there's no config.mailgun.supportEmail, the link won't be displayed.

// 移动 removeLanguagePrefix 函数到这里
const removeLanguagePrefix = (path: string) => {
  const segments = path.split('/').filter(segment => segment !== '');
  if (languages.some(lang => lang.locale === segments[0])) {
    return '/' + segments.slice(1).join('/');
  }
  return path;
};

const LanguageSwitcher = ({ locale }: { locale?: string }) => {
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const [isOpen, setIsOpen] = useState(false);

  // 获取当前选中语言的完整名称
  const currentLanguage = languages.find(lang => lang.locale === locale)?.language || 'English';

  return (
    <div 
      className="relative inline-block text-left"
      onMouseEnter={() => setIsOpen(true)}
      onMouseLeave={() => setIsOpen(false)}
    >
      <button
        className="inline-flex items-center justify-center px-3 py-1.5 border border-base-content/20 text-sm font-medium rounded-md text-base-content hover:bg-base-content/10 transition duration-300 ease-in-out"
      >
        <GlobeAltIcon className="w-4 h-4 mr-1" />
        {currentLanguage}
        <ChevronDownIcon 
          className={`ml-1 h-4 w-4 transition-transform duration-200 ${isOpen ? 'rotate-180' : ''}`} 
          aria-hidden="true" 
        />
      </button>

      <Transition
        show={isOpen}
        as={Fragment}
        enter="transition ease-out duration-200"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-150"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <div className="absolute bottom-full mb-1 left-0 md:left-auto md:right-0 z-30 w-36 origin-bottom-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-0.5">
            {languages.map((item) => {
              const hrefValue = `/${item.locale}${removeLanguagePrefix(pathname)}${
                searchParams.toString() ? `?${searchParams.toString()}` : ''
              }`;
              
              return (
                <Link
                  key={item.locale}
                  href={hrefValue}
                  className={`
                    flex items-center px-3 py-1.5 text-sm hover:bg-gray-50 transition-colors duration-150
                    ${locale === item.locale ? 'text-purple-600 font-medium bg-purple-50' : 'text-gray-600'}
                  `}
                >
                  {item.language}
                  {locale === item.locale && (
                    <span className="ml-auto text-purple-600">
                      <CheckIcon className="h-3.5 w-3.5" />
                    </span>
                  )}
                </Link>
              );
            })}
          </div>
        </div>
      </Transition>
    </div>
  );
};

const Footer = () => {
  const t = useTranslations('Footer');
  const pathname = usePathname();
  const currentLocale = pathname.split('/')[1] || 'default';

  return (
    <footer className="bg-base-200 border-t border-base-content/10">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12 sm:py-16 lg:py-24">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {/* Links column - 移到前面 */}
          <div className="space-y-4 order-1 md:order-2">
            <h3 className="footer-title font-semibold text-base-content tracking-wider text-sm">
              {t('LINKS')}
            </h3>
            <ul className="space-y-2 text-sm">
              {config.mailgun.supportEmail && (
                <li>
                  <a
                    href={`mailto:${config.mailgun.supportEmail}`}
                    target="_blank"
                    className="link link-hover"
                    aria-label="Contact Support"
                  >
                    {t('Support')}
                  </a>
                </li>
              )}
              <li>
                <a href="/blog" target="_blank" className="link link-hover">
                  {t('Blog')}
                </a>
              </li>
              {/* <li>
                <a href="https://github.com/AriaFlow/AriaFlow-ProductHunt-Daily" target="_blank" className="link link-hover">
                  GitHub
                </a>
              </li> */}
              <li>
                <a href="https://twitter.com/AriaFlowAI" target="_blank" className="link link-hover">
                  Twitter
                </a>
              </li>

              {/* <li>
                <a href="https://www.buymeacoffee.com/ariaflow.ai" target="_blank" className="link link-hover">
                  Buy me a coffee
                </a>
              </li>
              <li>
                <a href="https://www.patreon.com/AriaFlow" target="_blank" className="link link-hover">
                  Patreon
                </a>
              </li> */}
            </ul>
          </div>

          {/* Legal column */}
          <div className="space-y-4 order-2 md:order-3">
            <h3 className="footer-title font-semibold text-base-content tracking-wider text-sm">
              {t('LEGAL')}
            </h3>
            <ul className="space-y-2 text-sm">
              <li>
                <Link href="/tos" className="link link-hover">
                  {t('Terms of services')}
                </Link>
              </li>
              <li>
                <Link href="/privacy-policy" className="link link-hover">
                  {t('Privacy policy')}
                </Link>
              </li>
            </ul>
          </div>

          {/* Friends column */}
          <div className="space-y-4 order-3 md:order-4">
            <h3 className="footer-title font-semibold text-base-content tracking-wider text-sm">
              {t('FRIENDS')}
            </h3>
            <ul className="space-y-2 text-sm">
              {/* <li>
                <Link href="https://dokeyai.com/?via=ariaflow.ai" target="_blank" className="link link-hover">
                  DokeyAI
                </Link>
              </li> */}
              <li>
                <Link href="https://aitalk.im/?via=ariaflow.ai" target="_blank" className="link link-hover">
                  AITalk-AI Language Tutor
                </Link>
              </li>
              {/* <li>
                <Link href="https://woy.ai/?via=ariaflow.ai" title="Woy AI Tools Directory" target="_blank" className="link link-hover">
                  Woy AI Tools
                </Link>
              </li> */}
            </ul>
          </div>

          {/* Logo and description column - 移到最后 */}
          <div className="space-y-4 order-4 md:order-1">
            <Link
              href="/#"
              aria-current="page"
              className="flex items-center space-x-2"
            >
              <Image
                src={logo}
                alt={`${config.appName} logo`}
                priority={true}
                className="w-6 h-6"
                width={24}
                height={24}
              />
              <strong className="font-extrabold tracking-tight text-lg">
                {config.appName}
              </strong>
            </Link>
            <p className="text-sm text-base-content/80">
              {config.appDescription}
            </p>
            <p className="text-sm text-base-content/60">
              Copyright © {new Date().getFullYear()} - All rights reserved
            </p>
            <div className="flex items-center space-x-4 pt-4">
              <LanguageSwitcher locale={currentLocale} />
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
