"use client";

import { useState, useEffect, Fragment } from "react";
import type { JSX } from "react";
import { useSearchParams, usePathname } from "next/navigation";
import Link from "next/link";
import Image from "next/image";
import { Menu, Transition } from "@headlessui/react";
import { GlobeAltIcon, ChevronDownIcon, CheckIcon } from "@heroicons/react/16/solid";
import ButtonSignin from "./ButtonSignin";
import logo from "@/public/images/logo.png";
import config from "@/config";
import { useTranslations } from 'next-intl';
import { languages } from '../navigation'
import { createClientComponentClient } from '@supabase/auth-helpers-nextjs';


const Header = ({ locale }: { locale?: string }) => {
  const t = useTranslations('Header');
  const searchParams = useSearchParams();
  const pathname = usePathname();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const supabase = createClientComponentClient();

  // 检查用户登录状态
  useEffect(() => {
    const checkUser = async () => {
      const { data: { user } } = await supabase.auth.getUser();
      setIsLoggedIn(!!user);
    };
    checkUser();
  }, [supabase.auth]);

  const cta: JSX.Element = <ButtonSignin text={t('login')} extraStyle="btn-primary" />;

  const links: { href: string; label: string; }[] = [
    { href: "/#feature", label: t('features') },
    // { href: "/#use-cases", label: t('useCases') },
    { href: "/#how-it-works", label: t('HowItWorks') },
    { href: "/pricing", label: t('Pricing') },
    { href: "/#faq", label: t('faq') },
    // { href: "/dashboard", label: t('Dashboard') },
  ];

  useEffect(() => {
    setIsOpen(false);
  }, [searchParams]);

  return (
    <header className="fixed top-0 left-0 right-0 z-50">
      {/* 背景层 */}
      <div className="absolute inset-0 bg-white/95 border-b border-gray-100" />
      
      <div className="relative container mx-auto px-3 sm:px-6">
        <nav className="flex items-center justify-between h-14 sm:h-16" aria-label="Global">
          {/* 左侧部分：菜单按钮 + Logo */}
          <div className="flex items-center">
            {/* 移动端菜单按钮 - 减小尺寸和间距 */}
            <button
              type="button"
              className="lg:hidden inline-flex items-center justify-center p-1.5 mr-2.5 rounded-lg text-gray-700 hover:text-gray-900 hover:bg-gray-100/50 transition-colors duration-300"
              onClick={() => setIsOpen(true)}
            >
              <svg className="h-[18px] w-[18px]" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
              </svg>
            </button>

            {/* Logo + 产品名 */}
            <div className="flex items-center">
              {/* Logo 只在大屏显示 */}
              <div className="hidden lg:flex items-center justify-center w-8 h-8 bg-red-500 rounded-lg">
                <Image
                  src={logo}
                  alt={`${config.appName} logo`}
                  className="w-6 h-6"
                  placeholder="blur"
                  priority={true}
                  width={24}
                  height={24}
                />
              </div>
              {/* 产品名 - 响应式字体大小 */}
              <Link href="/" className="flex items-center">
                <span className="font-bold text-[17px] sm:text-xl text-gray-900 lg:ml-2">
                  AriaFlow.ai
                </span>
              </Link>
            </div>
          </div>

          {/* 中间导航链接 - 保持不变 */}
          <div className="hidden lg:flex lg:items-center lg:justify-center lg:flex-1 space-x-8">
            {links.map((link) => (
              <Link 
                href={link.href} 
                key={link.href} 
                className="text-base font-medium text-gray-600 hover:text-gray-900 transition-colors duration-200"
              >
                {link.label}
              </Link>
            ))}
          </div>

          {/* 右侧按钮组 - 优化移动端间距和大小 */}
          <div className="flex items-center gap-3 sm:gap-6">
            {!isLoggedIn ? (
              <>
                <Link
                  href="/login"
                  className="text-[15px] sm:text-base font-medium text-gray-600 hover:text-gray-900 transition-colors duration-200"
                >
                  {t('login')}
                </Link>
                <Link
                  href="/signup"
                  className="px-3 sm:px-4 py-1.5 sm:py-2 text-[15px] sm:text-base font-medium text-white bg-[#4F46E5] hover:bg-[#4338CA] rounded-lg transition-colors duration-200"
                >
                  {t('startForFree')}
                </Link>
              </>
            ) : (
              <Link
                href="/dashboard"
                className="px-3 sm:px-4 py-1.5 sm:py-2 text-[15px] sm:text-base font-medium text-white bg-[#4F46E5] hover:bg-[#4338CA] rounded-lg transition-colors duration-200"
              >
                {t('dashboard')}
              </Link>
            )}
          </div>
        </nav>
      </div>

      {/* 移动端菜单 - 优化样式 */}
      <div className={`lg:hidden ${isOpen ? "block" : "hidden"}`}>
        <div className="fixed inset-0 z-50 bg-gray-900/30 backdrop-blur-sm transition-opacity duration-300" 
             onClick={() => setIsOpen(false)} />
        <div className="fixed inset-y-0 right-0 z-50 w-full max-w-[280px] bg-white/95 backdrop-blur-xl shadow-2xl">
          <div className="flex items-center justify-between px-4 h-14 border-b border-gray-100">
            <Link 
              className="flex items-center gap-2" 
              href="/" 
              onClick={() => setIsOpen(false)}
            >
              <div className="flex items-center justify-center w-7 h-7 bg-red-500 rounded-lg">
                <Image
                  src={logo}
                  alt={`${config.appName} logo`}
                  className="w-5 h-5"
                  placeholder="blur"
                  priority={true}
                  width={20}
                  height={20}
                />
              </div>
              <span className="font-bold text-[17px] text-gray-900">
                {config.appName}
              </span>
            </Link>
            <button 
              type="button" 
              className="p-1.5 rounded-lg text-gray-400 hover:text-gray-500 hover:bg-gray-100/50" 
              onClick={() => setIsOpen(false)}
            >
              <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
          <div className="px-4 py-5">
            <div className="flex flex-col space-y-3">
              {links.map((link) => (
                <Link
                  href={link.href}
                  key={link.href}
                  className="text-lg font-medium text-gray-900 hover:text-blue-600 transform transition-all duration-300 hover:translate-x-2"
                  onClick={() => setIsOpen(false)}
                >
                  {link.label}
                </Link>
              ))}
            </div>
            
            <div className="mt-6 space-y-4">
              {!isLoggedIn && (
                <div className="w-full">
                  {cta}
                </div>
              )}
              
              {isLoggedIn ? (
                <Link
                  href="/dashboard"
                  className="w-full inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-gradient-to-r from-blue-500 to-purple-600 hover:from-blue-600 hover:to-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 transition-all duration-300 group"
                  onClick={() => setIsOpen(false)}
                >
                  Dashboard
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4 ml-2 transition-transform duration-300 group-hover:translate-x-1"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </Link>
              ) : (
                <Link
                  href="/signup"
                  className="w-full inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-gradient-to-r from-blue-500 to-purple-600 hover:from-blue-600 hover:to-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 transition-all duration-300 group"
                  onClick={() => setIsOpen(false)}
                >
                  {t('startForFree')}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4 ml-2 transition-transform duration-300 group-hover:translate-x-1"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M14 5l7 7m0 0l-7 7m7-7H3"
                    />
                  </svg>
                </Link>
              )}
            </div>

            <div className="mt-6 pt-6 border-t border-gray-200">
              <a 
                href="https://twitter.com/intent/user?&region=follow&screen_name=AriaFlow" 
                className="flex items-center space-x-2 text-blue-600 hover:text-blue-800"
                target="_blank"
                rel="noopener noreferrer"
              >
                <svg className="h-5 w-5" viewBox="0 0 24 24">
                  <path fill="currentColor" d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
                </svg>
                <span className="text-sm">@AriaFlow</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
